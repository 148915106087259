
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<TextWithColorElementComponent>({})
export default class TextWithColorElementComponent extends Vue {
  @Prop({ required: true })
  displayString?: string;

  @Prop({ required: true })
  readonly color!: string;

  get textColor(): string {
    return 'text-' + this.color;
  }
}
